import { gql } from "@apollo/client";
import {
  ClientAnalyticsEventType,
  SqlDialect,
  getQuotingCharacters,
} from "@hex/common";
import type {
  AutocompleteParseResult,
  AutocompleteParser,
} from "@hex/sql-autocomplete-parser";
import { countBy } from "lodash";
import { editor as Editor, IPosition, IRange, languages } from "monaco-editor";

import {
  SchemaElementSearchColumnFilter,
  SchemaElementSearchSchemaFilter,
  SchemaElementSearchTableFilter,
} from "../../../generated/graphqlTypes.js";
import { logInfoMsg } from "../../../util/logging";
import { trackEvent } from "../../../util/trackEvent";

import {
  ColumnSearchResultsForSqlCompletionFragment,
  DatabaseSearchResultsForSqlCompletionFragment,
  SchemaSearchResultsForSqlCompletionFragment,
  TableSearchResultsForSqlCompletionFragment,
} from "./sqlCompletionItems.generated";
import { EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD } from "./useMonacoCompletion";

gql`
  fragment DatabaseSearchResultsForSqlCompletion on DatabaseSearchResults {
    databaseResultCount
    databaseSearchResults {
      name
    }
  }
`;

gql`
  fragment SchemaSearchResultsForSqlCompletion on SchemaSearchResults {
    schemaResultCount
    schemaSearchResults {
      name
      dataSourceDatabase {
        name
      }
    }
  }
`;

gql`
  fragment TableSearchResultsForSqlCompletion on TableSearchResults {
    tableResultCount
    tableSearchResults {
      name
      dataSourceSchema {
        name
        dataSourceDatabase {
          name
        }
      }
    }
  }
`;

gql`
  fragment ColumnSearchResultsForSqlCompletion on ColumnSearchResults {
    columnResultCount
    columnSearchResults {
      name
      type
      dataSourceTable {
        name
        dataSourceSchema {
          name
        }
      }
    }
  }
`;

/**
 * Creates a string suitable for a monaco `CompletionItem` `sortText` field.
 * @param categoryWeight A number from 0 to 100 - higher categoryWeight items always show before lower ones
 * @param weight A number up to a milliion - higher weight items show first in their category
 */
const weightToSortText = (categoryWeight: number, weight: number): string =>
  (100 - categoryWeight).toString().padStart(3, "0") +
  (1_000_000 - weight).toString().padStart(7, "0");

const DBO_DETAIL_STRING = ["database", "schema", "table"];

//#region hue AutocompleteParseResult suggestions to monaco CompletionItem helpers
// For examples of hue result structures, see their tests:
// https://github.com/cloudera/hue/blob/master/desktop/core/src/desktop/js/parse/sql/generic/test/genericAutocompleteParser.Select.test.js

type SuggestionMetadata = {
  currentWord: string;
  range: IRange;
  schemaSearch: SchemaSearchHelpers;
  suggestDatabases: boolean;
  defaultDatabaseName?: string;
  enableSqlCompletionProviderLogs?: boolean;
};

const generateDatabaseCompletionItems = async (
  categoryWeight: number,
  { lowerCase, suggestDatabases }: AutocompleteParseResult,
  {
    currentWord,
    enableSqlCompletionProviderLogs,
    range,
    schemaSearch,
  }: SuggestionMetadata,
): Promise<languages.CompletionList> => {
  if (!suggestDatabases) {
    return { suggestions: [] };
  }

  const searchResults = await schemaSearch.getDatabases(currentWord);

  let insertTextStart =
    (suggestDatabases.prependQuestionMark ? "? " : "") +
    (suggestDatabases.prependFrom ? "FROM " : "");
  let insertTextEnd = suggestDatabases.appendBacktick ? "`" : "";
  insertTextEnd += suggestDatabases.appendDot ? "." : "";

  if (lowerCase) {
    insertTextStart = insertTextStart.toLowerCase();
    insertTextEnd = insertTextEnd.toLowerCase();
  }

  const incomplete =
    searchResults.databaseResultCount >
    searchResults.databaseSearchResults.length;

  if (enableSqlCompletionProviderLogs) {
    logInfoMsg("Finished generating database completion results", {
      safe: {
        totalCount: searchResults.databaseResultCount,
        returnedCount: searchResults.databaseSearchResults.length,
        incomplete,
      },
    });
  }

  return {
    suggestions: searchResults.databaseSearchResults
      .filter((db) => db.name)
      .map((db) => ({
        detail: "database",
        // checked by .filter call
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        label: db.name!,
        insertText: insertTextStart + db.name + insertTextEnd,
        kind: languages.CompletionItemKind.EnumMember,
        sortText: weightToSortText(categoryWeight, 0),
        command: {
          id: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
          title: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
          arguments: ["database"],
        },
        range,
      })),
    incomplete,
  };
};

const generateSchemaCompletionItems = async (
  categoryWeight: number,
  { lowerCase, suggestSchemas }: AutocompleteParseResult,
  {
    currentWord,
    defaultDatabaseName,
    enableSqlCompletionProviderLogs,
    range,
    schemaSearch,
    suggestDatabases,
  }: SuggestionMetadata,
): Promise<languages.CompletionList> => {
  if (!suggestSchemas) {
    return { suggestions: [] };
  }

  // Note: suggestSchemas.databaseName will only be set if the parser can tell
  // from context what the db name is. For example if you are completeing
  //   SELECT * FROM foo.|
  // We don't necessarily know what type of thing "foo" refers to but for the
  // sake of suggesting schemas we can assume it would have to be the database
  // in this context. Other completions (e.g. suggesting tables) would assume
  // It's the schema (if single-database or a default database is specified)
  const scopedDatabaseName = suggestSchemas.databaseName ?? defaultDatabaseName;
  const filters = scopedDatabaseName
    ? [
        {
          databaseName: scopedDatabaseName,
          schemaName: null,
        },
      ]
    : undefined;

  const searchResults = await schemaSearch.getSchemas(currentWord, { filters });

  let insertTextStart =
    (suggestSchemas.prependQuestionMark ? "? " : "") +
    (suggestSchemas.prependFrom ? "FROM " : "");
  if (lowerCase) {
    insertTextStart = insertTextStart.toLowerCase();
  }

  const schemaNameCounts = countBy(
    searchResults.schemaSearchResults,
    ({ name }) => name,
  );
  const duplicateSchemaNames = new Set(
    Object.keys(schemaNameCounts).filter((name) => schemaNameCounts[name] > 1),
  );

  const incomplete =
    searchResults.schemaResultCount > searchResults.schemaSearchResults.length;

  if (enableSqlCompletionProviderLogs) {
    logInfoMsg("Finished generating schema completion results", {
      safe: {
        totalCount: searchResults.schemaResultCount,
        returnedCount: searchResults.schemaSearchResults.length,
        incomplete,
      },
    });
  }

  return {
    suggestions: searchResults.schemaSearchResults
      // don't suggest empty schemas (e.g. Dataframe SQL)
      .filter((schema) => Boolean(schema.name))
      .map((schema) => {
        const databaseName = schema.dataSourceDatabase.name;
        const identifiers = [schema.name];
        if (
          suggestDatabases &&
          databaseName &&
          databaseName.toLowerCase() !== scopedDatabaseName?.toLowerCase()
        ) {
          // if we're the multi-database context and the schema does not belong to
          // the db in scope (either the defaultDatabaseName or inferred because
          // e.g. we're completing "someDatabase.|", then we want to insert the db
          // as well and possibly include it in the label if the label would
          // otherwise be ambiguous (same schema name in multiple dbs).
          identifiers.unshift(databaseName);
        }

        return {
          detail: "schema",
          label: duplicateSchemaNames.has(schema.name)
            ? concatIdentifiers(identifiers)
            : schema.name,
          insertText:
            insertTextStart + concatIdentifiers(identifiers, suggestSchemas),
          kind: languages.CompletionItemKind.Class,
          sortText: weightToSortText(categoryWeight, 0),
          command: {
            id: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
            title: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
            arguments: ["schema"],
          },
          range,
        };
      }),
    incomplete,
  };
};

const generateColumnCompletionItems = async (
  categoryWeight: number,
  { lowerCase, suggestColumns }: AutocompleteParseResult,
  {
    currentWord,
    defaultDatabaseName,
    enableSqlCompletionProviderLogs,
    range,
    schemaSearch,
  }: SuggestionMetadata,
): Promise<languages.CompletionList> => {
  if (!suggestColumns) {
    return { suggestions: [] };
  }

  let insertTextEnd = suggestColumns.appendBacktick ? "`" : "";

  if (lowerCase) {
    insertTextEnd = insertTextEnd.toLowerCase();
  }

  const filters = suggestColumns.tables
    .map((t) => {
      const identifierNames = (t.identifierChain ?? []).map(({ name }) => name);
      let databaseName: string | null = null;
      let schemaName: string | null = null;
      let tableName: string | null = null;
      if (identifierNames.length === 3) {
        [databaseName, schemaName, tableName] = identifierNames;
      } else if (identifierNames.length === 2) {
        [schemaName, tableName] = identifierNames;
      } else if (identifierNames.length === 1) {
        [tableName] = identifierNames;
      }

      return {
        databaseName: databaseName ?? defaultDatabaseName ?? null,
        schemaName: schemaName ?? null,
        tableName: tableName ?? null,
      };
    })
    // If we don't have a schemaName or a tableName, remove the filter.
    // This could happen in the case, for example, when querying a CTE.
    .filter((f) => f.schemaName != null || f.tableName != null);

  // If we have no filters at all, it means we'd return every column for the data connection,
  // which is overkill and we don't want to do that.
  if (filters.length === 0) {
    return { suggestions: [] };
  }

  const searchResults = await schemaSearch.getColumns(currentWord, {
    filters,
  });
  const incomplete =
    searchResults.columnResultCount > searchResults.columnSearchResults.length;
  if (enableSqlCompletionProviderLogs) {
    logInfoMsg("Finished generating column completion results", {
      safe: {
        totalCount: searchResults.columnResultCount,
        returnedCount: searchResults.columnSearchResults.length,
        incomplete,
      },
    });
  }

  return {
    suggestions: searchResults.columnSearchResults.map((c) => ({
      detail: c.type,
      label: c.name,
      insertText: c.name + insertTextEnd,
      kind: languages.CompletionItemKind.Variable,
      sortText: weightToSortText(categoryWeight, 0),
      command: {
        id: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
        title: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
        arguments: ["column"],
      },
      range,
    })),
    incomplete,
  };
};

const generateIdentifierCompletionItems = async (
  categoryWeight: number,
  { suggestIdentifiers }: AutocompleteParseResult,
  { range }: SuggestionMetadata,
): Promise<languages.CompletionList> => {
  if (!suggestIdentifiers) {
    return { suggestions: [] };
  }

  // these are typically names or aliases for tables provided in a `FROM` clause,
  // we can simply return them essentially as is.
  return {
    suggestions: suggestIdentifiers.map((s) => ({
      label: s.name,
      insertText: s.name,
      kind: languages.CompletionItemKind.Value,
      detail: s.type,
      sortText: weightToSortText(categoryWeight, 0),
      command: {
        id: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
        title: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
        arguments: ["identifier"],
      },
      range,
    })),
  };
};

const generateTableCompletionItems = async (
  categoryWeight: number,
  { lowerCase, suggestTables }: AutocompleteParseResult,
  {
    currentWord,
    defaultDatabaseName,
    enableSqlCompletionProviderLogs,
    range,
    schemaSearch,
    suggestDatabases,
  }: SuggestionMetadata,
): Promise<languages.CompletionList> => {
  if (!suggestTables) {
    return { suggestions: [] };
  }

  let filters: SchemaElementSearchTableFilter[] | undefined;

  const identifierNames = (suggestTables.identifierChain ?? []).map(
    ({ name }) => name,
  );
  const completingWithSchemaAndDb = identifierNames.length === 2;
  if (completingWithSchemaAndDb) {
    // If we're completing "foo.bar.|" then we can assume that foo is a database
    // and bar is a schema.
    const [databaseName, schemaName] = identifierNames;
    filters = [
      {
        databaseName,
        schemaName,
        tableName: null,
      },
    ];
  } else if (identifierNames.length === 1) {
    // When completing "foo.|" things are more ambiguous. In the single database
    // case it must be a schema but in the multi-database case it could be a
    // database but may be a schema if there's a defaultDatabaseName
    if (suggestDatabases) {
      if (defaultDatabaseName) {
        const [ambiguousName] = identifierNames;
        filters = [
          {
            databaseName: defaultDatabaseName,
            schemaName: ambiguousName,
            tableName: null,
          },
          {
            databaseName: ambiguousName,
            schemaName: null,
            tableName: null,
          },
        ];
      } else {
        const [databaseName] = identifierNames;
        filters = [
          {
            databaseName,
            schemaName: null,
            tableName: null,
          },
        ];
      }
    } else {
      const [schemaName] = identifierNames;
      filters = [
        {
          databaseName: defaultDatabaseName ?? null,
          schemaName: schemaName,
          tableName: null,
        },
      ];
    }
  } else {
    filters = defaultDatabaseName
      ? [
          {
            databaseName: defaultDatabaseName,
            schemaName: null,
            tableName: null,
          },
        ]
      : [];
  }

  const searchResults = await schemaSearch.getTables(currentWord, {
    filters,
  });

  let insertTextStart =
    // $0 marks where the cursor should go if we are in `InsertAsSnippet` mode
    (suggestTables.prependQuestionMark ? "?$0 " : "") +
    (suggestTables.prependFrom ? "FROM " : "");
  if (lowerCase) {
    insertTextStart = insertTextStart.toLowerCase();
  }

  const tableNameCounts = countBy(
    searchResults.tableSearchResults,
    ({ name }) => name,
  );
  const duplicateTableNames = new Set(
    Object.keys(tableNameCounts).filter((name) => tableNameCounts[name] > 1),
  );
  const incomplete =
    searchResults.tableResultCount > searchResults.tableSearchResults.length;

  if (enableSqlCompletionProviderLogs) {
    logInfoMsg("Finished generating table completion results", {
      safe: {
        totalCount: searchResults.tableResultCount,
        returnedCount: searchResults.tableSearchResults.length,
        incomplete,
      },
    });
  }

  // return items for all the table names in all the schemas we care about
  return {
    suggestions: searchResults.tableSearchResults.map((table) => {
      const schemaName = table.dataSourceSchema.name;
      const databaseName = table.dataSourceSchema.dataSourceDatabase.name;

      const qualifiedTableName = [table.name];
      if (schemaName && !completingWithSchemaAndDb) {
        qualifiedTableName.unshift(schemaName);
      }
      if (
        suggestDatabases &&
        databaseName &&
        databaseName !== defaultDatabaseName
      ) {
        qualifiedTableName.unshift(databaseName);
      }

      const existingIdentifiers = identifierNames.slice();
      if (
        suggestDatabases &&
        defaultDatabaseName &&
        existingIdentifiers[0] === defaultDatabaseName
      ) {
        // shave off first identifier if it's defaultDatabaseName
        // we don't put it on qualifiedTableName
        existingIdentifiers.shift();
      }

      const identifiersToInsert = scopeTableName({
        existingIdentifiers,
        qualifiedTableName,
      });

      return {
        detail: "table",
        label: duplicateTableNames.has(table.name)
          ? concatIdentifiers(identifiersToInsert)
          : table.name,
        insertText:
          insertTextStart +
          concatIdentifiers(identifiersToInsert, suggestTables),
        insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
        kind: languages.CompletionItemKind.Value,
        sortText: weightToSortText(categoryWeight, 0),
        command: {
          id: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
          title: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
          arguments: ["table"],
        },
        range,
      };
    }),
    incomplete,
  };
};

interface ScopeTableNameArgs {
  existingIdentifiers: string[];
  qualifiedTableName: string[];
}
/**
 * Given a list of identifiers that already exist in the SQL, and the qualified
 * table name (another list of identifiers including the database and schema name
 * the table belongs to), returns the "scoped" table name which is the suffix of
 * qualifiedTableName that should be inserted.
 */
function scopeTableName({
  existingIdentifiers,
  qualifiedTableName,
}: ScopeTableNameArgs): string[] {
  const firstMissing = qualifiedTableName.findIndex(
    (id, idx) => id.toLowerCase() !== existingIdentifiers[idx]?.toLowerCase(),
  );
  return qualifiedTableName.slice(firstMissing);
}

const generateKeywordCompletionItems = async (
  categoryWeight: number,
  { lowerCase, suggestKeywords }: AutocompleteParseResult,
  { range }: SuggestionMetadata,
): Promise<languages.CompletionList> => {
  if (!suggestKeywords) {
    return { suggestions: [] };
  }

  // simply return items for all the suggested keywords
  return {
    suggestions: suggestKeywords.map((s) => ({
      label: lowerCase ? s.value.toLowerCase() : s.value,
      insertText: lowerCase ? s.value.toLowerCase() : s.value,
      kind: languages.CompletionItemKind.Keyword,
      sortText: weightToSortText(categoryWeight, s.weight),
      command: {
        id: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
        title: EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
        arguments: ["keyword"],
      },
      range,
    })),
  };
};

/**
 * Concatenates a list of identifers with support for appending backticks and dots.
 * Note: When more than one identifier is specified there will always be a dot between
 * identifiers but if appendDot is specified one will be added to the end as well.
 * When appendBackticks is specified all identifiers will be wrapped with backticks
 * except the first one which will only have it added to the end.
 */
function concatIdentifiers(
  identifiers: string[],
  {
    appendBacktick,
    appendDot,
  }: { appendDot?: boolean; appendBacktick?: boolean } = {},
): string {
  const maybeBacktick = appendBacktick ? "`" : "";
  const maybeDot = appendDot ? "." : "";
  return identifiers.reduce((res, ident, idx) => {
    const last = idx === identifiers.length - 1;
    if (!last) {
      return res + ident + maybeBacktick + "." + maybeBacktick;
    } else {
      return res + ident + maybeBacktick + maybeDot;
    }
  }, "");
}
//#endregion

/**
 * Transform a set of results from Cloudera Hue's SQL Autcomplete parser
 * in to CompletionItems consumable by monaco.
 */
export const autocompleteParseResultToMonacoCompletionList = async (
  results: AutocompleteParseResult,
  metadata: SuggestionMetadata,
): Promise<languages.CompletionList> => {
  const completionLists = await Promise.all([
    generateIdentifierCompletionItems(110, results, metadata), // suggestIdentifiers
    metadata.suggestDatabases
      ? generateDatabaseCompletionItems(100, results, metadata)
      : { suggestions: [], incomplete: false }, // suggestDatabases
    generateSchemaCompletionItems(90, results, metadata), // suggestSchemas
    generateTableCompletionItems(80, results, metadata), // suggestTables
    generateColumnCompletionItems(70, results, metadata), // suggestColumns
    generateKeywordCompletionItems(60, results, metadata), // suggestKeywords
  ]);
  const [identifiers, dbs, schemas, tables, columns, keywords] =
    completionLists;

  if (metadata.enableSqlCompletionProviderLogs) {
    logInfoMsg("Done generating completions", {
      safe: {
        identifiers: {
          count: identifiers.suggestions.length,
          incomplete: identifiers.incomplete,
          resultsSuggestIdentifiers: results.suggestIdentifiers != null,
        },
        databases: {
          count: dbs.suggestions.length,
          incomplete: dbs.incomplete,
          metadataSuggestDatabases: metadata.suggestDatabases,
          resultsSuggestDatabases: results.suggestDatabases != null,
        },
        schemas: {
          count: schemas.suggestions.length,
          incomplete: schemas.incomplete,
          resultsSuggestSchemas: results.suggestSchemas != null,
        },
        tables: {
          count: tables.suggestions.length,
          incomplete: tables.incomplete,
          resultsSuggestTables: results.suggestTables != null,
        },
        columns: {
          count: columns.suggestions.length,
          incomplete: columns.incomplete,
          resultsSuggestColumns: results.suggestColumns != null,
        },
        keywords: {
          count: keywords.suggestions.length,
          incomplete: keywords.incomplete,
          resultsSuggestKeywords: results.suggestKeywords != null,
        },
      },
    });
  }

  return {
    suggestions: completionLists.flatMap((c) => c.suggestions),
    // If any one of our completion item generators didn't return full results, the final result is incomplete.
    // When a result is marked as imcomplete, monaco will retrigger our completion provider as the user types.
    // If a result _isn't_ incomplete, then monaco just filters it down itself as they type without re-calling our provider.
    incomplete: completionLists.some((c) => c.incomplete),
  };

  // Note: Our `AutocompleteParseResult` object gives us a bunch more functionality we aren't yet using.
  // For example, these fields are all provided, some of which might be usable for even better autocomplete:
  // suggestAggregateFunctions
  // suggestAnalyticFunctions
  // suggestColRefKeywords
  // suggestColumnAliases
  // suggestCommonTableExpressions
  // suggestFilters
  // suggestFunctions
  // suggestGroupBys
  // suggestHdfs
  // suggestJoins
  // suggestJoinConditions
  // suggestKeyValues
  // suggestOrderBys
  // suggestSetOptions
  // suggestValues
};

export type SchemaSearchHelpers = {
  getDatabases: (
    query: string,
  ) => Promise<DatabaseSearchResultsForSqlCompletionFragment>;
  getSchemas: (
    query: string,
    options?: { filters?: SchemaElementSearchSchemaFilter[] },
  ) => Promise<SchemaSearchResultsForSqlCompletionFragment>;
  getTables: (
    query: string,
    options?: { filters?: SchemaElementSearchTableFilter[] },
  ) => Promise<TableSearchResultsForSqlCompletionFragment>;
  getColumns: (
    query: string,
    options?: { filters?: SchemaElementSearchColumnFilter[] },
  ) => Promise<ColumnSearchResultsForSqlCompletionFragment>;
};

export interface AutocompleteOptions {
  schemaSearch: SchemaSearchHelpers;
  parser: AutocompleteParser;
  suggestDatabases: boolean;
  defaultDatabaseName?: string;
  dialect: SqlDialect;
  enableSqlCompletionProviderLogs?: boolean;
}

export const getCompletionItemsForModelAtPosition = async (
  model: Editor.ITextModel,
  position: IPosition,
  {
    defaultDatabaseName,
    dialect,
    enableSqlCompletionProviderLogs,
    parser,
    schemaSearch,
    suggestDatabases,
  }: AutocompleteOptions,
): Promise<languages.CompletionList> => {
  // For mysterious reasons, the SQL parser can't handle double quotes (or SQLServer's
  // gross square bracket syntax) in the query, even though that's the standard way to
  // reference case-sensitive identifiers in SQL, but if we replace double quotes with
  // backticks it works fine. So we do that before parsing, and handle the rest of the
  // quoting logic in `transformSuggestion` below.
  const contents =
    dialect === "sqlserver" || dialect === "cloudsql__sqlserver"
      ? model.getValue().replaceAll("[", "`").replaceAll("]", "`")
      : model.getValue().replaceAll('"', "`");
  const offset = model.getOffsetAt(position);

  const rawAutocompleteResults = parser.parseSql(
    contents.substring(0, offset),
    contents.substring(offset),
  );

  const word = model.getWordAtPosition(position);
  const startColumn = word?.startColumn ?? position.column;
  const range: IRange = {
    startLineNumber: position.lineNumber,
    endLineNumber: position.lineNumber,
    startColumn,
    endColumn: word?.endColumn ?? position.column,
  };
  const resolvedWord = word?.word;
  let completionList = await autocompleteParseResultToMonacoCompletionList(
    rawAutocompleteResults,
    {
      range,
      currentWord: resolvedWord ?? "",
      schemaSearch,
      defaultDatabaseName,
      suggestDatabases,
      enableSqlCompletionProviderLogs,
    },
  );

  // compute the characters immediately before and after the word being completed
  const prevChar = contents[startColumn - 2];
  const nextChar = contents[startColumn + (resolvedWord?.length ?? 0) - 1];
  // If the user is currently typing a word and it's not double-quoted, respect the casing
  // of the in-progress word, BUT only for things other than database objects (with the
  // exception of non-mixed-case snowflake dbos, which we special case)
  if (resolvedWord && prevChar !== "`") {
    if (resolvedWord === resolvedWord.toLowerCase()) {
      completionList = {
        ...completionList,
        suggestions: completionList.suggestions.map((s) =>
          isDbo(s) && !isUniformCaseSnowflakeDbo(s, dialect)
            ? s
            : {
                ...s,
                insertText: s.insertText.toLowerCase(),
                label: (s.label as string).toLowerCase(),
              },
        ),
      };
    } else if (resolvedWord === resolvedWord.toUpperCase()) {
      completionList = {
        ...completionList,
        suggestions: completionList.suggestions.map((s) =>
          isDbo(s) && !isUniformCaseSnowflakeDbo(s, dialect)
            ? s
            : {
                ...s,
                insertText: s.insertText.toUpperCase(),
                label: (s.label as string).toUpperCase(),
              },
        ),
      };
    }
  }
  completionList = {
    ...completionList,
    suggestions: completionList.suggestions.map((s) =>
      transformSuggestion({
        suggestion: s,
        prevChar,
        nextChar,
        position,
        dialect,
      }),
    ),
  };
  // uncomment these lines for easy DevX when trying to debug or add new autocomplete features
  // console.log("current word", word?.word);
  // console.log("raw results", rawAutocompleteResults);
  // console.log("transformed results", completionList);

  if (completionList.suggestions.length > 0) {
    trackEvent(ClientAnalyticsEventType.AUTOCOMPLETE_TRIGGERED, {
      autocompleteProviderType: "SQL",
    });
  }
  return completionList;
};

/**
 * Helper function to determine whether an autocomplete suggestion represents a database
 * object (database, schema, table, or column)
 */
function isDbo(suggestion: languages.CompletionItem): boolean {
  return (
    suggestion.kind === languages.CompletionItemKind.Variable ||
    (suggestion.detail != null && DBO_DETAIL_STRING.includes(suggestion.detail))
  );
}

/**
 * Helper function to determine whether an autocomplete suggestion represents a snowflake
 * dbo that is either all uppercase or all lowercase
 */
function isUniformCaseSnowflakeDbo(
  suggestion: languages.CompletionItem,
  dialect: SqlDialect,
): boolean {
  if (!isDbo(suggestion) || dialect !== "snowflake") {
    return false;
  }
  const splitInsertText = suggestion.insertText.split(" ");
  const dboReference = splitInsertText[splitInsertText.length - 1];
  return (
    dboReference === dboReference.toLowerCase() ||
    dboReference === dboReference.toUpperCase()
  );
}

/**
 * Helper function to properly handle quoting of case-sensitive database objects
 */
function transformSuggestion({
  dialect,
  nextChar,
  position,
  prevChar,
  suggestion,
}: {
  suggestion: languages.CompletionItem;
  prevChar: string;
  nextChar: string;
  position: IPosition;
  dialect: SqlDialect;
}): languages.CompletionItem {
  // The actual completion to be inserted might be more than the name of the object,
  // e.g. if you complete in the context `select * |`, you'll get `select * from <dbo>`,
  let dboReference: string;
  if (dialect === "duckdb") {
    dboReference = suggestion.label as string;
  } else {
    const splitInsertText = suggestion.insertText.split(" ");
    dboReference = splitInsertText[splitInsertText.length - 1];
  }
  if (
    // only transform database objects that are case sensitive or have spaces
    isDbo(suggestion) &&
    ((dboReference.toLowerCase() !== dboReference &&
      !isUniformCaseSnowflakeDbo(suggestion, dialect)) ||
      dboReference.includes(" "))
  ) {
    const [openQuote, closeQuote] = getQuotingCharacters(dialect);
    // transform a reference like `database.schema.table` to `database"."schema"."table"`
    // note that we do NOT prepend an opening quote, because it might already be there
    // (that's why we pass in `prevChar`)
    const transformedDboReference = `${dboReference
      .replaceAll("`", "")
      .split(".")
      .filter((substr) => substr.length > 0)
      .join(`${closeQuote}.${openQuote}`)}${closeQuote}${
      suggestion.insertText.endsWith(".") ? "." : ""
    }`;
    // If the user has already typed an opening quote, insert the text without prepending a quote
    if (prevChar === "`") {
      return {
        ...suggestion,
        insertText: suggestion.insertText.replace(
          dboReference,
          transformedDboReference,
        ),
        // This is a little weird, but if the closing quote is already there (e.g. if completing
        // in the context `select * from "|"`), we need to delete it by inserting a null string
        // so we properly wind up with the cursor in the right place, i.e. `select * from "<dbo>"|`
        additionalTextEdits:
          nextChar === "`"
            ? [
                {
                  text: null,
                  range: {
                    startLineNumber: position.lineNumber,
                    startColumn: position.column,
                    endLineNumber: position.lineNumber,
                    endColumn: position.column + 1,
                  },
                },
              ]
            : undefined,
      };
    } else {
      // If the user has not typed the opening quote, add it in
      return {
        ...suggestion,
        insertText: suggestion.insertText.replace(
          dboReference,
          `${openQuote}${transformedDboReference}`,
        ),
      };
    }
  }
  return suggestion;
}
