import { CellId, uuid } from "@hex/common";
import { editor as Editor, Uri } from "monaco-editor";
import { useCallback, useEffect, useRef } from "react";

interface useImperativeModelArgs {
  language: string;
  cellId: CellId;
}

const modelsByCellId: Record<string, Editor.ITextModel | undefined> = {};

/**
 * Initializes an empty model. Does not accept a source argument as source is to be
 * entirely controlled by the consumer via Monaco edit operations.
 */
export function useImperativeModel({
  cellId,
  language,
}: useImperativeModelArgs): Editor.ITextModel {
  const modelRef = useRef<Editor.ITextModel>();
  const modelGetter = useCallback(() => {
    const emptySource = "";
    if (!modelRef.current) {
      modelRef.current = Editor.createModel(
        emptySource,
        language,
        Uri.from({
          scheme: "inmemory",
          authority: "model",
          path: `/${uuid()}`,
        }),
      );
    }

    return modelRef.current;
  }, [language]);

  useEffect(() => {
    modelsByCellId[cellId] = modelRef.current;
    return () => {
      delete modelsByCellId[cellId];
      if (modelRef.current) {
        modelRef.current.dispose();
      }
    };
  }, [cellId]);

  return modelGetter();
}

export function getImperativeModel(
  cellId: CellId,
): Editor.ITextModel | undefined {
  return modelsByCellId[cellId];
}
