import { uuid } from "@hex/common";
import type { editor as Editor, Position, languages } from "monaco-editor";

export abstract class BroadcastCompletionProvider<T>
  implements languages.CompletionItemProvider
{
  private completions: {
    [id: string]: T;
  };
  private listeners: {
    [id: string]: () => void;
  };
  constructor() {
    this.completions = {};
    this.listeners = {};
  }

  /**
   * Subscribe to completion additions/removals. Returns method to unsubscribe.
   */
  subscribe(callback: () => void): () => void {
    const id = uuid();
    this.listeners[id] = callback;
    return () => {
      delete this.listeners[id];
    };
  }

  private broadcast(): void {
    Object.values(this.listeners).forEach((listener) => listener());
  }

  addCompletion(id: string, completion: T): void {
    this.completions[id] = completion;
    this.broadcast();
  }

  addCompletions(completions: Array<{ id: string; completion: T }>): void {
    for (const { completion, id } of completions) {
      this.completions[id] = completion;
    }
    this.broadcast();
  }

  removeCompletion(id: string): void {
    delete this.completions[id];
    this.broadcast();
  }

  removeCompletions(ids: string[]): void {
    for (const id of ids) {
      delete this.completions[id];
    }
    this.broadcast();
  }

  getCompletions(): T[] {
    return [...Object.values(this.completions)];
  }

  abstract provideCompletionItems(
    model: Editor.ITextModel,
    position: Position,
  ): languages.ProviderResult<languages.CompletionList>;
}
