import {
  ClientAnalyticsEventType,
  MONACO_SQL_LANGUAGE_TYPES,
} from "@hex/common";
import { editor as Editor, languages } from "monaco-editor";
import { useEffect } from "react";

import { useSelectCell } from "../../../hooks/cell/useSelectCell";
import { useDispatch } from "../../../redux/hooks";
import {
  setSearchActive,
  setSearchInput,
} from "../../../redux/slices/schemaTreeBrowserSlice";
import { trackEvent } from "../../../util/trackEvent";
import { useOpenDataBrowserSidebar } from "../../data/useOpenDataBrowserSidebar.js";

import {
  INLINE_CODE_COMPLETION_PROVIDER,
  KERNEL_COMPLETION_PROVIDER,
  MagicCompletionData,
} from "./KernelCompletionProvider";
import { useTrackInlineCompletionEventReviewedMutation } from "./KernelCompletionProvider.generated.js";
import { PARAMETER_COMPLETION_PROVIDER } from "./ParameterCompletionProvider";
import { SQL_COMPLETION_PROVIDER } from "./SqlCompletionProvider";

// HACK HACK HACK prevent setting the language completions multiple times.
// this is in a seperate file to prevent webpack hot reload from
// resetting it.
let completionLoaded = false;
export const EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD =
  "emitSqlAutoCompleteAcceptedMetric";

export const TRACK_MAGIC_INLINE_ACCEPTED_CMD = "trackMagicInlineAccepted";
const escapeRegex = (regex: string): string => {
  // Escape the value for the concatenated regexp.
  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
  return regex.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
};

export function useMonacoCompletion(): void {
  const openConnectionsSidebar = useOpenDataBrowserSidebar();
  const dispatch = useDispatch();
  const [trackInlineCompletionReviewed] =
    useTrackInlineCompletionEventReviewedMutation();

  const { selectCells } = useSelectCell();
  useEffect(() => {
    if (!completionLoaded) {
      completionLoaded = true;
      for (const language of ["python", "r"]) {
        languages.registerCompletionItemProvider(
          language,
          KERNEL_COMPLETION_PROVIDER,
        );
      }

      const parameterCompletionLanguages = [...MONACO_SQL_LANGUAGE_TYPES, "r"];
      for (const language of parameterCompletionLanguages) {
        languages.registerCompletionItemProvider(
          language,
          PARAMETER_COMPLETION_PROVIDER,
        );
      }

      Editor.registerCommand(
        "autoRename",
        (_accessor, { modelUri, newVariable, oldVariable }) => {
          const model = Editor.getModel(modelUri);
          if (model) {
            const value = model.getValue();
            // Not a proper replace, will also affect comments, strings, etc.
            // Just a hack for now but this is okay
            const newValue = value.replace(
              new RegExp(`${escapeRegex(oldVariable)}\\b`, "g"),
              newVariable,
            );
            model.setValue(newValue);
          }
        },
      );
      Editor.registerCommand("selectCell", (_accessor, { cellId }) => {
        selectCells(cellId);
      });
      Editor.registerCommand(
        EMIT_SQL_AUTOCOMPLETION_ACCEPTED_METRIC_CMD,
        (_accessor, suggestionType) => {
          trackEvent(ClientAnalyticsEventType.AUTOCOMPLETE_ACCEPTED, {
            autocompleteProviderType: "SQL",
            suggestionType: suggestionType,
          });
        },
      );
      Editor.registerCommand(
        TRACK_MAGIC_INLINE_ACCEPTED_CMD,
        (_accessor, args: MagicCompletionData) => {
          if (INLINE_CODE_COMPLETION_PROVIDER.lastCompletion != null) {
            void trackInlineCompletionReviewed({
              variables: {
                ...args,
                accepted: true,
              },
            });
            INLINE_CODE_COMPLETION_PROVIDER.lastCompletion.accepted = true;
          }
        },
      );
      Editor.registerCommand(
        "viewTableInSchemaBrowser",
        (_accessor, { dataConnectionId, table }) => {
          openConnectionsSidebar(dataConnectionId);
          dispatch(
            setSearchActive({
              dataConnectionId,
              searchActive: true,
              showPinnedOnly: false,
            }),
          );
          dispatch(
            setSearchInput({
              dataConnectionId,
              searchInput: `table:"${table}"`,
            }),
          );
        },
      );
      for (const language of MONACO_SQL_LANGUAGE_TYPES) {
        languages.registerCompletionItemProvider(
          language,
          SQL_COMPLETION_PROVIDER,
        );
      }
      for (const language of [...MONACO_SQL_LANGUAGE_TYPES]) {
        languages.registerInlineCompletionsProvider(
          language,
          INLINE_CODE_COMPLETION_PROVIDER,
        );
      }
      for (const language of ["python", "r", "markdown"]) {
        languages.registerInlineCompletionsProvider(
          language,
          INLINE_CODE_COMPLETION_PROVIDER,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this once ever
  }, []);
}
