import {
  AUTOMATIC_PARAMETERS,
  MONACO_SQL_LANGUAGE_TYPES,
  ParameterInputType,
  ParameterOutputType,
} from "@hex/common";
import { uniqBy } from "lodash";
import { editor as Editor, Position, Range, languages } from "monaco-editor";

import { BroadcastCompletionProvider } from "./BroadcastCompletionProvider";

export const getSuggestion = ({
  completion,
  position,
  word,
}: {
  completion: ParameterCompletion;
  position: Position;
  word?: Editor.IWordAtPosition;
}): languages.CompletionItem => {
  const startColumn = word?.startColumn ?? position.column;
  return {
    label: completion.name,
    insertText: completion.name,
    filterText: completion.name,
    kind: languages.CompletionItemKind.Variable,
    detail: completion.label || undefined,
    documentation: `${
      completion.inputType ? `Input type: ${completion.inputType}\n` : ""
    }Output type: ${completion.outputType}\n${completion.description ?? ""}`,
    range: {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn,
      endColumn: word?.endColumn ?? position.column,
    },
  };
};

const _isInJinjaBlockHelper = (
  model: Editor.ITextModel,
  position: Position,
  [openingBrackets, closingBrackets]: [string, string],
): boolean => {
  const findPrevMatch = (text: string): Editor.FindMatch | null =>
    model.findPreviousMatch(text, position, false, true, null, false);

  const jinjaOpen = findPrevMatch(openingBrackets);
  if (
    // We have no jinja blocks anywhere, so we're obviously not in one
    jinjaOpen == null ||
    // The first opening brackets we found actually comes after our `position`, which means we're not in a jinja block
    position.isBeforeOrEqual(jinjaOpen.range.getStartPosition())
  ) {
    return false;
  }

  const jinjaClose = findPrevMatch(closingBrackets);
  return (
    // If we have no closing brackets, then we're probably in a jinja block
    jinjaClose == null ||
    // If our closing brackets are between our opening brackets and our `position`,
    // then the jinja block is closed by them and we're not inside of it,
    // so return true if that's not the case.
    !Range.fromPositions(
      jinjaOpen.range.getStartPosition(),
      position,
    ).containsRange(jinjaClose.range)
  );
};

const isInJinjaBlock = (
  model: Editor.ITextModel,
  position: Position,
): boolean =>
  _isInJinjaBlockHelper(model, position, ["{{", "}}"]) ||
  _isInJinjaBlockHelper(model, position, ["{%", "%}"]);

export interface ParameterCompletion {
  inputType?: ParameterInputType;
  outputType: ParameterOutputType;
  name: string;
  label?: string | null;
  description?: string;
}

export class ParameterCompletionProvider extends BroadcastCompletionProvider<ParameterCompletion> {
  override getCompletions(): ParameterCompletion[] {
    const completions = [
      ...super.getCompletions(),
      ...AUTOMATIC_PARAMETERS.map((automaticParameter) => ({
        outputType: automaticParameter.outputType,
        description: automaticParameter.description,
        name: automaticParameter.name,
      })),
    ];
    // TODO(reactivity): find conflicts in parameters and highlight them (e.g. two places provide the same parameter)
    return uniqBy(completions, "name");
  }

  provideCompletionItems(
    model: Editor.ITextModel,
    position: Position,
  ): languages.ProviderResult<languages.CompletionList> {
    if (model.isDisposed()) {
      return { suggestions: [] };
    }

    const isSQL = (MONACO_SQL_LANGUAGE_TYPES as readonly string[]).includes(
      model.getLanguageId(),
    );

    // We don't want to autocomplete Python-based names at all in SQL code unless inside a Jinja block
    if (isSQL && !isInJinjaBlock(model, position)) {
      return { suggestions: [] };
    }

    const word = model.getWordAtPosition(position);
    const prevCharacter = model.getLineContent(position.lineNumber)[
      position.column - 2 // 1-indexed and we want the previous, so we subtract 2
    ];
    if (word) {
      return {
        suggestions: this.getCompletions().map((completion) =>
          getSuggestion({
            completion,
            position,
            word,
          }),
        ),
      };
    } else if (prevCharacter === "$") {
      return {
        suggestions: this.getCompletions().map((completion) =>
          getSuggestion({
            completion,
            position,
          }),
        ),
      };
    }
  }
}

export const PARAMETER_COMPLETION_PROVIDER = new ParameterCompletionProvider();
